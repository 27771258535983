import React, { Component } from 'react';

import iconCompanyImg from './assets/images/logo.png';
import iconDivisionImg from './assets/images/icon-division.png';
import iconEmailImg from './assets/images/icon-email.png';
import iconLinkedinImg from './assets/images/icon-linkedin.png';
import iconPhoneImg from './assets/images/icon-phone.png';
import iconPlaceImg from './assets/images/icon-place.png';
import iconWebsiteImg from './assets/images/icon-website.png';


class SignatureView extends Component {

    constructor(props) {
        super(props);

        this.opts = {
            // picture: { width: 420, height: 81 },
            // picture: { width: 1844, height: 382 }
            picture: { width: 1844, height: 500 }
        }

        this.state = {
            
            // Resources
            iconCompany: null,
            iconDivision: null,
            iconEmail: null,
            iconLinkedin: null,
            iconPhone: null,
            iconPlace: null,
            iconWebsite: null,

            // For await resource loads
            awaitStack: [ ],
            declaringResources: true,

            // Inputs
            name  : '',
            job   : '',
            division: '',
            email : '',
            phone : ''
        };
    }

    componentDidMount() {
        // Declare all resources
        const iconCompany = new Image();
        const iconDivision = new Image();
        const iconEmail = new Image();
        const iconLinkedin = new Image();
        const iconPhone = new Image();
        const iconPlace = new Image();
        const iconWebsite = new Image();

        // Add resources await stack
        this.setState({ awaitStack: this.state.awaitStack.concat('iconCompany') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconDivision') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconEmail') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconLinkedin') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconPhone') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconPlace') });
        this.setState({ awaitStack: this.state.awaitStack.concat('iconWebsite') });

        iconCompany.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconCompany') });
        iconDivision.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconDivision') });
        iconEmail.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconEmail') });
        iconLinkedin.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconLinkedin') });
        iconPhone.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconPhone') });
        iconPlace.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconPlace') });
        iconWebsite.onload = () => this.setState({ awaitStack: this.state.awaitStack.filter(val => val !== 'iconWebsite') });

        // Load all resources
        iconCompany.src = iconCompanyImg;
        iconDivision.src = iconDivisionImg;
        iconEmail.src = iconEmailImg;
        iconLinkedin.src = iconLinkedinImg;
        iconPhone.src = iconPhoneImg;
        iconPlace.src = iconPlaceImg;
        iconWebsite.src = iconWebsiteImg;

        // Add resources to state
        this.setState({
            declaringResources: false,
            iconCompany: iconCompany,
            iconDivision: iconDivision,
            iconEmail: iconEmail,
            iconLinkedin: iconLinkedin,
            iconPhone: iconPhone,
            iconPlace: iconPlace,
            iconWebsite: iconWebsite
        });
    }

    getImageURL() {
        const aspectRatio = ((this.opts.picture.width + this.opts.picture.height) / 2);
        const canvas = this.createCanvas();
        const ctx = canvas.getContext('2d');
    
        this.fillCanvasBackground(ctx, aspectRatio);
        this.drawCompanyIcon(ctx, aspectRatio);
        this.drawSeparator(ctx, aspectRatio);
        this.drawName(ctx, aspectRatio);
        this.drawJob(ctx, aspectRatio);
        this.drawContactInfo(ctx, aspectRatio);
        this.drawAreaInfo(ctx, aspectRatio);
    
        return canvas.toDataURL();
    }
    
    createCanvas() {
        const canvas = document.createElement('canvas');
        canvas.setAttribute('width', this.opts.picture.width);
        canvas.setAttribute('height', this.opts.picture.height);
        return canvas;
    }
    
    fillCanvasBackground(ctx, aspectRatio) {
        ctx.beginPath();
        ctx.rect(0, 0, this.opts.picture.width, this.opts.picture.height);
        ctx.fillStyle = '#fff';
        ctx.fill();
    }
    
    drawCompanyIcon(ctx, aspectRatio) {
        ctx.drawImage(
            this.state.iconCompany,
            0, 0,
            this.state.iconCompany.width, this.state.iconCompany.height,
            aspectRatio * 0.05, aspectRatio * 0.15,
            aspectRatio * 0.675, aspectRatio * 0.100
        );
    }
    
    drawSeparator(ctx, aspectRatio) {
        ctx.beginPath();
        ctx.rect(
            aspectRatio * 0.753, aspectRatio * 0.043,
            aspectRatio * 0.009, aspectRatio * 0.35
        );
        ctx.fillStyle = '#3e91d5';
        ctx.fill();
    }
    
    drawText(ctx, text, x, y, fontSize, color, align) {
        ctx.fillStyle = color;
        ctx.font = `bold ${fontSize}px "Source Sans 3"`;
        ctx.textAlign = align;
        ctx.fillText(text, x, y);
    }
    
    drawName(ctx, aspectRatio) {
        const name = this.state.name.trim().length ? this.state.name.toUpperCase() : 'NOMBRE Y APELLIDO';
        this.drawText(ctx, name, aspectRatio * 0.815, aspectRatio * 0.085, aspectRatio * 0.05, '#3e91d5', 'left');
    }
    
    drawJob(ctx, aspectRatio) {
        const job = this.state.job.trim().length ? this.state.job.toUpperCase() : 'CARGO COMPLETO';
        this.drawText(ctx, job, aspectRatio * 0.815, aspectRatio * 0.13, aspectRatio * 0.035, '#44546a', 'left');
    }
    
    drawContactInfo(ctx, aspectRatio) {
        // Draw phone
        this.drawIconAndText(ctx, this.state.iconPhone, this.state.phone.trim().length ? this.state.phone : 'Número telefónico', aspectRatio, 0.195, 0.23);
    
        // Draw email
        this.drawIconAndText(ctx, this.state.iconEmail, this.state.email.trim().length ? this.state.email.toLowerCase() : 'Correo electrónico', aspectRatio, 0.265, 0.3);
    }
    
    drawIconAndText(ctx, icon, text, aspectRatio, yIcon, yText) {
        ctx.drawImage(
            icon, 0, 0,
            icon.width, icon.height,
            aspectRatio * 0.815, aspectRatio * yIcon,
            aspectRatio * 0.05, aspectRatio * 0.05
        );
        this.drawText(ctx, text, aspectRatio * 0.88, aspectRatio * yText, aspectRatio * 0.035, '#44546a', 'left');
    }
    
    drawAreaInfo(ctx, aspectRatio) {
        if (this.state.division !== 'transversal') {
            // Draw division icon and text
            ctx.drawImage(
                this.state.iconDivision, 0, 0,
                this.state.iconDivision.width, this.state.iconDivision.height,
                aspectRatio * 0.815, aspectRatio * 0.335,
                aspectRatio * 0.05, aspectRatio * 0.05
            );
    
            const division = this.state.division.trim().length ? this.state.division : 'Área';
            this.drawText(ctx, division, aspectRatio * 0.88, aspectRatio * 0.374, aspectRatio * 0.035, '#44546a', 'left');
    
            // Add company URL
            this.drawText(ctx, "https://insidesecurity.cl", aspectRatio * 0.2, aspectRatio * 0.3, aspectRatio * 0.04, '#3e91d5', 'left');
        }
    }
    
    submitForm(event) {

        event.preventDefault();
        
        const image = document.querySelector('#preview');

        // Simulate download
        const a = document.createElement('a');
        a.href = image.getAttribute('src');
        a.setAttribute('download', 'firma.png');
        a.setAttribute('class', 'd-none');

        // Append to body
        document.body.appendChild(a);

        // Simulate click to download
        a.click();

        // Remove from body
        document.body.removeChild(a);
    }

    
    
    render() {
        return (
            <div className="container pt-5">
                <div className="card shadow-sm mx-auto mt-4" style={{ maxWidth: '700px' }}>
                    <div className="card-body">
                        
                        <h3 className="card-title text-center fw-bold">Generador de firma</h3>
                        <hr className="mb-4" />
                        <p className="mb-2 text-left">
                            Complete los campos requeridos y descargue su firma:
                        </p>
    
                        <form method="post" autoComplete="off" onSubmit={event => this.submitForm(event)}> 

                            <div className="mb-2">
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    placeholder="Nombre y apellido"
                                    required
                                    pattern="^[a-zA-ZáéíóúñÁÉÍÓÚÑ][a-zA-ZáéíóúñÁÉÍÓÚÑ\s,'`]{0,30}[a-záéíóúñ'`]$"
                                    onInput={(event) => {this.setState({ name: event.target.value}); }} />
                            </div>
                            <div className="mb-2">
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    placeholder="Cargo completo"
                                    required
                                    pattern="^[a-zA-Z0-9áéíóúñÁÉÍÓÚÑ\s,\-\.&\(\)]{1,128}$"
                                    onInput={(event) => this.setState({ job: event.target.value })} />
                            </div>
                            <div className="mb-2">
                                <input
                                    className="form-control form-control-sm"
                                    type="tel"
                                    placeholder="Número telefónico"
                                    required
                                    maxLength="16"
                                    pattern="^\+{0,1}[0-9\-\s]{8,16}$"
                                    onInput={(event) => this.setState({ phone: event.target.value})} />
                            </div>
                            <div className="mb-2">
                                <input
                                    className="form-control form-control-sm"
                                    type="email"
                                    placeholder="Correo electrónico"
                                    pattern='^[a-zA-Z0-9.!#$%&’*+\/=?^_`\{\|\}~\-]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*$'
                                    required
                                    onInput={(event) => this.setState({ email: event.target.value })} />
                            </div>
                            <div className="mb-2">
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    placeholder="Área"
                                    required
                                    pattern="^[a-zA-Z0-9áéíóúñÁÉÍÓÚÑ\s,\-\.&\(\)]{1,128}$"
                                    onInput={(event) => this.setState({ division: event.target.value })} />
                            </div>
        
                            <p className="mt-4 mb-2 text-left">Vista previa:</p>
                            <div className="mb-2 p-1 border rounded text-center">
                                {(this.state.awaitStack.length || this.state.declaringResources) ? (
                                    <p className="text-center text-muted p-4">Cargando</p>
                                ) : (
                                    <img
                                        id="preview"
                                        className="img-fluid"
                                        src={this.getImageURL()}
                                        alt="Imagen Generada" />
                                )}
                            </div>

                            <div>
                                <button type="submit" className="btn btn-primary px-4 w-100">
                                    <i className="bi bi-download me-2"></i>
                                    Descargar imagen
                                </button>
                            </div>
                            <small className="d-block text-center mt-3">
                                Generador de firma Inside Security &copy; {new Date().getFullYear()} 
                            </small>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
    
    
}

export default SignatureView;
