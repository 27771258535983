import React, { Component } from 'react';
import Layout from '../../layout';
import SignatureView from '../../views/corporate_signature/signature';


class SignatureController extends Component {
    render() {
        return (
            <Layout
                body={SignatureView}
                navigate={this.props.navigate} />
        );
    }
}

export default SignatureController;
